// import React from "react";

// export const Inquiry = () => {

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const formData = {
//       name: e.target.name.value,
//       email: e.target.email.value,
//       phone_number: e.target.phone_number.value,
//       address: e.target.address.value,
//       subject: e.target.subject.value,
//       select_product: e.target.select_product.value,
//       message: e.target.message.value
//     };

//     fetch('https://chemilex.com/api.php', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(formData),
//     })
//       .then((response) => response.json())  // Expecting JSON response
//       .then((data) => {
//         alert(data.message);
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   };

//   return (
//     <>
//       <div className="section-full">
//         <div className="container">
//           <div className="section-head">
//             <h2>Inquiry Form </h2>
//             <div className="mt-separator-outer m-b30">
//               <div className="mt-separator site-bg-primary" />
//             </div>
//           </div>
//           {/* GOOGLE MAP & CONTACT FORM */}
//           <div className="section-content m-b50">
//             {/* CONTACT FORM*/}
//             <div className="mt-box">
//               <div className="p-a30 bg-gray radius-10 cons-contact-form-wrap">
//                 <form
//                   className="cons-contact-form contact-style-1"

//                 >
//                   <div className="row">
//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <input
//                           name="name"
//                           type="text"
//                           required
//                           className="form-control"
//                           placeholder="Name"
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <input
//                           name="email"
//                           type="text"
//                           className="form-control"
//                           required
//                           placeholder="Email"
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <input
//                           name="phone_number"
//                           type="number"
//                           className="form-control"
//                           required
//                           placeholder="Phone Number"
//                         />
//                       </div>
//                     </div>

//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <input
//                           name="address"
//                           type="text"
//                           className="form-control"
//                           required
//                           placeholder="Address"
//                         />
//                       </div>
//                     </div>

//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <input
//                           name="subject"
//                           type="text"
//                           className="form-control"
//                           required
//                           placeholder="Subject"
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-6">
//                       <div className="form-group">
//                        <select name="select_product" id="products"  className="form-control">
//                         <option value="0" selected disabled>Select Product</option>
//                         <option value="HydratedLime">Hydrated Lime  </option>
//                         <option value="Calcined/QuickLime">Calcined/Quick Lime  </option>
//                         <option value="LimeStone">Lime Stone</option>
//                         <option value="Calcined/QuickLimePowder">Calcined/Quick Lime Powder</option>
//                         <option value="PrimeHardCookingCoal">Prime Hard Cooking Coal</option>
//                         <option value="IndonesianSteamCoal">Indonesian Steam Coal</option>
//                         <option value="SouthAfricanSteamCoal">South African Steam Coal</option>
//                         <option value="PICCoal">PIC Coal</option>
//                         <option value="AnthraciteCoal">Anthracite Coal</option>
//                         <option value="ChromeConcentrate">Chrome Concentrate</option>
//                         <option value="Gypsum">Gypsum</option>
//                         <option value="ImportedManageneseOre(East Africa)">Imported Managenese Ore(East Africa)</option>
//                         <option value="IndonesianMETCoke">Indonesian MET Coke</option>
//                         <option value="ChinaMETCoke">China MET Coke</option>
//                         <option value="14">Colombian MET Coke</option>
//                         <option value="ColombianMETCoke">Colombian(ULP)</option>
//                         <option value="IndianLAMCoke">Indian LAM Coke</option>
//                         <option value="RailTransport">Rail Transport</option>
//                         <option value="RoadTransport">Road Transport</option>
//                        </select>
//                       </div>
//                     </div>

//                     <div className="col-md-12">
//                       <div className="form-group">
//                         <textarea
//                           name="message"
//                           rows={5}
//                           className="form-control "
//                           required
//                           placeholder="Message"
//                           defaultValue={""}
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-12">
//                       <button
//                         name="Resat"
//                         type="reset"
//                         value="Reset"
//                         className="site-button m-r10"
//                       >
//                         Reset
//                       </button>
//                       <button
//                         name="submit"
//                         type="submit"
//                         value="Submit"
//                         className="site-button-secondry"
//                       >
//                         Submit{" "}
//                       </button>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// import React from "react";

// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// export const Inquiry = () => {
 
//   const generateCaptchaText = () => {
//     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     let result = '';
//     for (let i = 0; i < 6; i++) {
//       result += characters.charAt(Math.floor(Math.random() * characters.length));
//     }
//     return result;
//   };
   

//   const handleSubmit = (e) => {
//     e.preventDefault();

    

//     const formData = {
//       name: e.target.name.value,
//       email: e.target.email.value,
//       phone_number: e.target.phone_number.value,
//       address: e.target.address.value,
//       subject: e.target.subject.value,
//       select_product: e.target.select_product.value,
//       message: e.target.message.value,
  
//     };

//     fetch("https://chemilex.com/api.php", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(formData),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status === "success") {
//           toast.success(data.message || "Form submitted successfully!", {
//             position: "top-right",
//             autoClose: 5000,
//           });
//         } else {
//           toast.error(
//             data.message || "Failed to submit the form. Please try again.",
//             {
//               position: "top-right",
//               autoClose: 5000,
//             }
//           );
//         }
 
//       })
//       .catch((error) => {
//         toast.error("Error: Unable to submit form.", {
//           position: "top-right",
//           autoClose: 5000,
//         });
//         console.error("Error:", error);
//       });
//   };

//   return (
//     <>
//       <div className="section-full">
//         <div className="container">
//           <div className="section-head">
//             <h2>Inquiry Form</h2>
//             <div className="mt-separator-outer m-b30">
//               <div className="mt-separator site-bg-primary" />
//             </div>
//           </div>
//           <div className="section-content m-b50">
//             <div className="mt-box">
//               <div className="p-a30 bg-gray radius-10 cons-contact-form-wrap">
//                 <form
//                   className="cons-contact-form contact-style-1"
//                   onSubmit={handleSubmit}
//                 >
//                   <div className="row">
//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <input
//                           name="name"
//                           type="text"
//                           required
//                           className="form-control"
//                           placeholder="Name"
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <input
//                           name="email"
//                           type="text"
//                           className="form-control"
//                           required
//                           placeholder="Email"
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <input
//                           name="phone_number"
//                           type="number"
//                           className="form-control"
//                           required
//                           placeholder="Phone Number"
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <input
//                           name="address"
//                           type="text"
//                           className="form-control"
//                           required
//                           placeholder="Address"
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <input
//                           name="subject"
//                           type="text"
//                           className="form-control"
//                           required
//                           placeholder="Subject"
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <select
//                           name="select_product"
//                           id="products"
//                           className="form-control"
//                         >
//                           <option value="0" selected disabled>
//                             Select Product
//                           </option>
//                           <option value="HydratedLime">Hydrated Lime</option>
//                           <option value="Calcined/QuickLime">
//                             Calcined/Quick Lime
//                           </option>
//                           <option value="LimeStone">Lime Stone</option>
//                           <option value="Calcined/QuickLimePowder">
//                             Calcined/Quick Lime Powder
//                           </option>
//                           <option value="PrimeHardCookingCoal">
//                             Prime Hard Cooking Coal
//                           </option>
//                           <option value="IndonesianSteamCoal">
//                             Indonesian Steam Coal
//                           </option>
//                           <option value="SouthAfricanSteamCoal">
//                             South African Steam Coal
//                           </option>
//                           <option value="PICCoal">PIC Coal</option>
//                           <option value="AnthraciteCoal">
//                             Anthracite Coal
//                           </option>
//                           <option value="ChromeConcentrate">
//                             Chrome Concentrate
//                           </option>
//                           <option value="Gypsum">Gypsum</option>
//                           <option value="ImportedManageneseOre(East Africa)">
//                             Imported Managenese Ore(East Africa)
//                           </option>
//                           <option value="IndonesianMETCoke">
//                             Indonesian MET Coke
//                           </option>
//                           <option value="ChinaMETCoke">China MET Coke</option>
//                           <option value="ColombianMETCoke">
//                             Colombian MET Coke
//                           </option>
//                           <option value="Colombian(ULP)">Colombian(ULP)</option>
//                           <option value="IndianLAMCoke">Indian LAM Coke</option>
//                           <option value="RailTransport">Rail Transport</option>
//                           <option value="RoadTransport">Road Transport</option>
//                         </select>
//                       </div>
//                     </div>
//                     <div className="col-md-12">
//                       <div className="form-group">
//                         <textarea
//                           name="message"
//                           rows={5}
//                           className="form-control"
//                           required
//                           placeholder="Message"
//                           defaultValue={""}
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-12">
//                       <div className="form-group">
//                        <input type="text"  />
//                       </div>
//                     </div>

//                     <div className="col-md-12">
//                       <button
//                         name="Resat"
//                         type="reset"
//                         value="Reset"
//                         className="site-button m-r10"
//                       >
//                         Reset
//                       </button>
//                       <button
//                         name="submit"
//                         type="submit"
//                         value="Submit"
//                         className="site-button-secondry"
//                       >
//                         Submit{" "}
//                       </button>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <ToastContainer />
//     </>
//   );
// };

// import React, { useState, useEffect } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// export const Inquiry = () => {
//   const [captchaText, setCaptchaText] = useState('');
//   const [userInput, setUserInput] = useState('');

//   const generateCaptchaText = () => {
//     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     let result = '';
//     for (let i = 0; i < 6; i++) {
//       result += characters.charAt(Math.floor(Math.random() * characters.length));
//     }
//     return result;
//   };

//   const handleGenerateCaptcha = () => {
//     setCaptchaText(generateCaptchaText());
//   };

//   useEffect(() => {
//     handleGenerateCaptcha(); // Generate CAPTCHA on initial render
//   }, []);

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (userInput !== captchaText) {
//       toast.error("The CAPTCHA entered is incorrect.", {
//         position: "top-right",
//         autoClose: 5000,
//       });
//       return;
//     }

//     const formData = {
//       name: e.target.name.value,
//       email: e.target.email.value,
//       phone_number: e.target.phone_number.value,
//       address: e.target.address.value,
//       subject: e.target.subject.value,
//       select_product: e.target.select_product.value,
//       message: e.target.message.value,
//     };

//     fetch("https://chemilex.com/api.php", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(formData),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status === "success") {
//           toast.success(data.message || "Form submitted successfully!", {
//             position: "top-right",
//             autoClose: 5000,
//           });
//         } else {
//           toast.error(data.message || "Failed to submit the form. Please try again.", {
//             position: "top-right",
//             autoClose: 5000,
//           });
//         }
//       })
//       .catch((error) => {
//         toast.error("Error: Unable to submit form.", {
//           position: "top-right",
//           autoClose: 5000,
//         });
//         console.error("Error:", error);
//       });
//   };

//   return (
//     <>
//       <div className="section-full">
//         <div className="container">
//           <div className="section-head">
//             <h2>Inquiry Form</h2>
//             <div className="mt-separator-outer m-b30">
//               <div className="mt-separator site-bg-primary" />
//             </div>
//           </div>
//           <div className="section-content m-b50">
//             <div className="mt-box">
//               <div className="p-a30 bg-gray radius-10 cons-contact-form-wrap">
//                 <form className="cons-contact-form contact-style-1" onSubmit={handleSubmit}>
//                   <div className="row">
//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <input name="name" type="text" required className="form-control" placeholder="Name" />
//                       </div>
//                     </div>
//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <input name="email" type="text" className="form-control" required placeholder="Email" />
//                       </div>
//                     </div>
//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <input name="phone_number" type="number" className="form-control" required placeholder="Phone Number" />
//                       </div>
//                     </div>
//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <input name="address" type="text" className="form-control" required placeholder="Address" />
//                       </div>
//                     </div>
//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <input name="subject" type="text" className="form-control" required placeholder="Subject" />
//                       </div>
//                     </div>
//                     <div className="col-md-6">
//                       <div className="form-group">
//                         <select name="select_product" id="products" className="form-control">
//                           <option value="0" selected disabled>
//                             Select Product
//                           </option>
//                           <option value="HydratedLime">Hydrated Lime</option>
//                           <option value="Calcined/QuickLime">Calcined/Quick Lime</option>
//                           <option value="LimeStone">Lime Stone</option>
//                           <option value="Calcined/QuickLimePowder">Calcined/Quick Lime Powder</option>
//                           <option value="PrimeHardCookingCoal">Prime Hard Cooking Coal</option>
//                           <option value="IndonesianSteamCoal">Indonesian Steam Coal</option>
//                           <option value="SouthAfricanSteamCoal">South African Steam Coal</option>
//                           <option value="PICCoal">PIC Coal</option>
//                           <option value="AnthraciteCoal">Anthracite Coal</option>
//                           <option value="ChromeConcentrate">Chrome Concentrate</option>
//                           <option value="Gypsum">Gypsum</option>
//                           <option value="ImportedManageneseOre(East Africa)">Imported Managenese Ore(East Africa)</option>
//                           <option value="IndonesianMETCoke">Indonesian MET Coke</option>
//                           <option value="ChinaMETCoke">China MET Coke</option>
//                           <option value="ColombianMETCoke">Colombian MET Coke</option>
//                           <option value="Colombian(ULP)">Colombian(ULP)</option>
//                           <option value="IndianLAMCoke">Indian LAM Coke</option>
//                           <option value="RailTransport">Rail Transport</option>
//                           <option value="RoadTransport">Road Transport</option>
//                         </select>
//                       </div>
//                     </div>
//                     <div className="col-md-12">
//                       <div className="form-group">
//                         <textarea name="message" rows={5} className="form-control" required placeholder="Message" defaultValue={""} />
//                       </div>
//                     </div>
//                     <div className="col-md-12">
//                       <div className="form-group">
//                         <label>CAPTCHA: {captchaText}</label>
//                         <input
//                           type="text"
//                           placeholder="Enter the CAPTCHA"
//                           value={userInput}
//                           onChange={(e) => setUserInput(e.target.value)}
//                           className="form-control"
//                         />
//                         <button type="button" onClick={handleGenerateCaptcha} className="site-button m-r10">
//                           Generate New CAPTCHA
//                         </button>
//                       </div>
//                     </div>
//                     <div className="col-md-12">
//                       <button name="Reset" type="reset" value="Reset" className="site-button m-r10">
//                         Reset
//                       </button>
//                       <button name="submit" type="submit" value="Submit" className="site-button-secondry">
//                         Submit
//                       </button>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <ToastContainer />
//     </>
//   );
// }; 


import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Inquiry = () => {
  const [captchaText, setCaptchaText] = useState('');
  const [userInput, setUserInput] = useState('');
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const generateCaptchaText = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleGenerateCaptcha = () => {
    setCaptchaText(generateCaptchaText());
    setIsCaptchaVerified(false);
    setUserInput('');
  };

  const handleVerifyCaptcha = () => {
    if (userInput === captchaText) {
      setIsCaptchaVerified(true);
      toast.success("CAPTCHA verified successfully!", {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      setIsCaptchaVerified(false);
      toast.error("CAPTCHA verification failed. Please try again.", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    handleGenerateCaptcha();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if CAPTCHA is verified
    if (!isCaptchaVerified) {
      toast.error("Please verify the CAPTCHA before submitting.", {
        position: "top-right",
        autoClose: 5000,
      });
      return; // Prevent submission
    }

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone_number: e.target.phone_number.value,
      address: e.target.address.value,
      subject: e.target.subject.value,
      select_product: e.target.select_product.value,
      message: e.target.message.value,
    };

    fetch("https://www.chemilex.com/inquiry.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          toast.success(data.message || "Form submitted successfully!", {
            position: "top-right",
            autoClose: 1000,
          });
        } else {
          toast.error(data.message || "Failed to submit the form. Please try again.", {
            position: "top-right",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {
        toast.error("Error: Unable to submit form.", {
          position: "top-right",
          autoClose: 1000,
        });
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div className="section-full">
        <div className="container">
          <div className="section-head">
            <h2>Inquiry Form</h2>
            <div className="mt-separator-outer m-b30">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          <div className="section-content m-b50">
            <div className="mt-box">
              <div className="p-a30 bg-gray radius-10 cons-contact-form-wrap">
                <form className="cons-contact-form contact-style-1" onSubmit={(e)=>{
                  handleSubmit(e)
                  setUserInput('')
                }}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input name="name" type="text" required className="form-control" placeholder="Name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input name="email" type="text" className="form-control" required placeholder="Email" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input name="phone_number" type="number" className="form-control" required placeholder="Phone Number" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input name="address" type="text" className="form-control" required placeholder="Address" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input name="subject" type="text" className="form-control" required placeholder="Subject" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <select name="select_product" id="products" className="form-control">
                          <option value="0" selected disabled>
                            Select Product
                          </option>
                          <option value="HydratedLime">Hydrated Lime</option>
                          <option value="Calcined/QuickLime">Calcined/Quick Lime</option>
                          <option value="LimeStone">Lime Stone</option>
                          <option value="Calcined/QuickLimePowder">Calcined/Quick Lime Powder</option>
                          <option value="PrimeHardCookingCoal">Prime Hard Cooking Coal</option>
                          <option value="IndonesianSteamCoal">Indonesian Steam Coal</option>
                          <option value="SouthAfricanSteamCoal">South African Steam Coal</option>
                          <option value="PICCoal">PIC Coal</option>
                          <option value="AnthraciteCoal">Anthracite Coal</option>
                          <option value="ChromeConcentrate">Chrome Concentrate</option>
                          <option value="Gypsum">Gypsum</option>
                          <option value="ImportedManageneseOre(East Africa)">Imported Managenese Ore(East Africa)</option>
                          <option value="IndonesianMETCoke">Indonesian MET Coke</option>
                          <option value="ChinaMETCoke">China MET Coke</option>
                          <option value="ColombianMETCoke">Colombian MET Coke</option>
                          <option value="Colombian(ULP)">Colombian(ULP)</option>
                          <option value="IndianLAMCoke">Indian LAM Coke</option>
                          <option value="RailTransport">Rail Transport</option>
                          <option value="RoadTransport">Road Transport</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea name="message" rows={5} className="form-control" required placeholder="Message" defaultValue={""} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>CAPTCHA: {captchaText}</label>
                        <input
                          type="text"
                          placeholder="Enter the CAPTCHA"
                          value={userInput}
                          onChange={(e) => setUserInput(e.target.value)}
                          className="form-control"
                        />
                        <button type="button" onClick={handleGenerateCaptcha} className="site-button m-r10">
                        Reset Captcha
                        </button>
                        <button type="button" onClick={handleVerifyCaptcha} className="site-button-secondry">
                          Verify Captcha
                        </button>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button name="Reset" type="reset" value="Reset" className="site-button m-r10">
                        Reset
                      </button>
                      <button name="submit" type="submit" value="Submit" className="site-button-secondry">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
