import { useEffect, useState } from "react";
// import FastImage from "../../../globals/elements/fastimg";
// import { loadScript, route } from "../../../globals/constants";
import { NavLink } from "react-router-dom";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import OwlCarousel from 'react-owl-carousel';
import TableRow from "@mui/material/TableRow";
import { Paper } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

const columns = [
  { id: "SPECIFICATION", label: "SPECIFICATION", minWidth: 200 },
  { id: "RESULT", label: "RESULT", minWidth: 200 },
 
];

function createData(SPECIFICATION, RESULT,  ) {
  return { SPECIFICATION, RESULT,   };
}

const rows = [
  createData("CaCO₃", "92-95",  ),
  createData("MgCO₃", "2.50(max.)",  ),
  createData("Silica", "1.50(max.)", ),
  createData("Fe₂O₃", "0.2(max.) ",),
  createData("Others", "0.6",),
  
  createData("Size", '0-6mm/6-25mm/10-30mm/25-50mm/40-80mm',  ),
  createData("Packing", "Loose",),
];
function QuickLimeStone() {
  useEffect(() => {
    loadScript("js/custom.js");
    
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const generateCaptchaText = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleGenerateCaptcha = () => {
    setCaptchaText(generateCaptchaText());
    setIsCaptchaVerified(false);
    setUserInput("");
  };

  const handleVerifyCaptcha = () => {
    if (userInput === captchaText) {
      setIsCaptchaVerified(true);
      toast.success("CAPTCHA verified successfully!", {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      setIsCaptchaVerified(false);
      toast.error("CAPTCHA verification failed. Please try again.", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    handleGenerateCaptcha();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if CAPTCHA is verified
    if (!isCaptchaVerified) {
      toast.error("Please verify the CAPTCHA before submitting.", {
        position: "top-right",
        autoClose: 5000,
      });
      return; // Prevent submission
    }

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone_number: e.target.number.value,
      message: e.target.message.value,
    };

    fetch("https://www.chemilex.com/contactus.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          toast.success(data.message || "Form submitted successfully!", {
            position: "top-right",
            autoClose: 1000,
          });
        } else {
          toast.error(
            data.message || "Failed to submit the form. Please try again.",
            {
              position: "top-right",
              autoClose: 1000,
            }
          );
        }
      })
      .catch((error) => {
        toast.error("Error: Unable to submit form.", {
          position: "top-right",
          autoClose: 1000,
        });
        console.error("Error:", error);
      });

    setUserInput(""); // Reset CAPTCHA input after form submission
  };

  return (
    <>
      {/* Service Detail */}
      <ToastContainer /> {/* Add this to enable toasts */}
      <div className="section-full  p-t80 p-b50  ">
        <div className="container  bg-white ">
          <div className="section-content ">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="mt-box">
                  <h2 className="m-t0"> Lime Stone: A Key Resource for Multiple Industries
                  </h2>

                  <p>
                  Limestone is a naturally occurring sedimentary rock primarily composed of calcium carbonate (CaCO₃). It is formed from the accumulation of marine organism shells, corals, and other organic materials over millions of years. Limestone serves as a critical raw material in lime manufacturing, where it is heated to produce quicklime (calcium oxide) and hydrated lime (calcium hydroxide). These compounds are essential in sectors such as construction, steel production, agriculture, water treatment, and more. 
                  </p>
                  <p>
                  In addition to lime production, limestone is also a valuable component in cement manufacturing, Glass Industry, Poultry Feed / Cattle Feed, Ceramic Industry, Rubber Industry, Oil Exploration, and as a base material for many infrastructure projects. Its versatility and abundance make it a cornerstone of modern industrial processes. 
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
              <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    >

               
                  <div className="item">
                    <div className="aon-thum-bx">
                      <FastImage
                        src="images/products/QuickLimeStone.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                 
               

                </OwlCarousel>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-full p-t80 p-b50">
      <div className="container bg-white">
        <div className="section-content">
          <div className="row">
            <div className="col-12">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <h3 style={{
                      marginLeft:"0px",
                      textAlign:"center"
              

                    }}>   Lime Stone Specification</h3>
                <TableContainer sx={{ maxHeight: 600 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                  
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align="center" // Adjust alignment if needed
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.SPECIFICATION}>
                            {columns.map((column) => (
                              <TableCell key={column.id} align="center"> {/* Adjust alignment if needed */}
                                {row[column.id]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </div>


      <div className="section-full p-t80 p-b50 bg-gray">
        <div className="container">
          {/* TITLE START*/}
          <div className="section-head text-center">
            <h2 data-title="Supplies"> Services</h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END*/}
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.HydratedLimePowder}>
                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    >

                  
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage
                            src="images/products/HydratedLimePowder(2).jpg"
                            alt=""
                          />
                        </div>
                      </div>
                     
                   
                    </OwlCarousel>
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">Hydrated Lime </h4>

                    <NavLink
                      to={route.Products.HydratedLimePowder}
                     className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.QuickLimePowder}>

                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    >
                    
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage
                             src="images/products/QuickLimeLumps.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      
                   
                    </OwlCarousel>
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">Calcined /Quick Lime </h4>

                    <NavLink
                      to={route.Products.QuickLimePowder}
                     className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.QuickLimeLumps}>
                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    >


                    
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage
                         
                              src="images/products/QuickLimePowder.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                       
                 
                    </OwlCarousel>
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">Calcined /Quick Lime Powder</h4>

                    <NavLink
                      to={route.Products.QuickLimeLumps}
                     className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full p-t80 p-b50 bg-white ">
        <div className="container  bg-white ">
          <div className="section-content ">
            <div className="row text-center">
              {/* <div className="col-lg-6 col-md-12">
              
                <div className="mt-box  need-help m-b40">
                  <h2>Need Assistance?</h2>
                  <p className="m-b15">
                    <b>
                      {" "}
                      Find answers to common questions about Chemilex, our products, and services. If you need further help, our team is always ready to assist.
                    </b>
                  </p>
                  <p>
                    {" "}
                    For decades, Chemilex has set the standard in quality and reliability. Our commitment to excellence ensures that every interaction you have with us meets your highest expectations. Whether you’re looking for detailed product information or have specific inquiries, we’re here to provide the support you need.
                  </p>
                  <ul className="list-check-circle primary m-b15">
                    <li>
                    Reach out to us for any questions or assistance related to our offerings. Your satisfaction is our top priority, and we’re dedicated to providing prompt and helpful responses to all your inquiries.
                    </li>
                    <li>
                    Simply contact us, and experience the Chemilex difference.
                    </li>
                  </ul>
                  <NavLink
                    to={route.pages.contact.CONTACT1}
                    className="site-button-link"
                  >
                    Contact Us
                  </NavLink>
                </div>
              </div> */}
                <div className="col-lg-12 col-md-12">
                {/* Quick Contact */}
                <div className="widget">
                  <h2> Contact Us</h2>
                  <div className="widget-quick-form">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          placeholder="Name"
                          className="form-control"
                          type="text"
                          name="name"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          placeholder="Email"
                          className="form-control"
                          type="text"
                          name="email"
                        />
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            name="number"
                            type="text"
                            className="form-control"
                            required
                            placeholder="Phone Number"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            rows={5}
                            className="form-control"
                            required
                            placeholder="Message"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>CAPTCHA: {captchaText}</label>
                          <input
                            type="text"
                            placeholder="Enter the CAPTCHA"
                            value={userInput}
                            onChange={(e) => setUserInput(e.target.value)}
                            className="form-control"
                          />
                          <button
                            type="button"
                            onClick={handleGenerateCaptcha}
                            className="site-button m-r10"
                          >
                            Generate Captcha
                          </button>
                          <button
                            type="button"
                            onClick={handleVerifyCaptcha}
                            className="site-button-secondry"
                          >
                            Verify Captcha
                          </button>
                        </div>
                      </div>
                      <div className="form-group">
                        <button type="submit" className="site-button">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default QuickLimeStone;
