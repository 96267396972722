import { useEffect, useState } from "react";
// import FastImage from "../../../globals/elements/fastimg";
// import { loadScript, route } from "../../../globals/constants";
import { NavLink } from "react-router-dom";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import OwlCarousel from 'react-owl-carousel';
import TableRow from "@mui/material/TableRow";
import { toast, ToastContainer } from "react-toastify";

const columns = [
  { id: "SPECIFICATION", label: "Specification", minWidth: 170 },
  { id: "Result1", label: "Result (%)", minWidth: 100 },
  { id: "Result2", label: "Result (%)", minWidth: 100 },
  { id: "Result3", label: "Result (%)", minWidth: 100 },
  { id: "Result4", label: "Result (%)", minWidth: 100 },
  { id: "Result5", label: "Result (%)", minWidth: 100 },
  { id: "Result6", label: "Result (%)", minWidth: 100 },
  { id: "Result7", label: "Result (%)", minWidth: 100 },
   
];

function createData(
  SPECIFICATION,
  result1,
  result2,
  result3,
  result4,
  result5,
  result6,
  result7,
  result8
) {
  return { SPECIFICATION, result1, result2, result3, result4, result5, result6, result7, result8 };
}

const rows = [
  createData("Available lime as CaCO3", 95, 90, 85, 80, 75, 70, 65,),
  createData("Acid Insoluble (max.)",  1, 1.5, 2.5, 3.5, 4, 4.5, 6),
  createData("Fe203",  "In Traces", "In Traces", "In Traces", "In Traces", "In Traces", "In Traces", "In Traces"),
  createData("MgCO3 (Magnesia)(Max.)", 2, 5, 5.25, 5.25, 5.5, 5, 8),
  createData("SiO2(Max.)",  1, 1, 1.5, 1.5, 1.5, 1.5, 1.5),
  createData("Size", 400, 275, 225, 200, 175, 150, 150),
];


function HydratedLimePowder() {
  useEffect(() => {
    loadScript("js/custom.js");
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const generateCaptchaText = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleGenerateCaptcha = () => {
    setCaptchaText(generateCaptchaText());
    setIsCaptchaVerified(false);
    setUserInput("");
  };

  const handleVerifyCaptcha = () => {
    if (userInput === captchaText) {
      setIsCaptchaVerified(true);
      toast.success("CAPTCHA verified successfully!", {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      setIsCaptchaVerified(false);
      toast.error("CAPTCHA verification failed. Please try again.", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    handleGenerateCaptcha();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if CAPTCHA is verified
    if (!isCaptchaVerified) {
      toast.error("Please verify the CAPTCHA before submitting.", {
        position: "top-right",
        autoClose: 5000,
      });
      return; // Prevent submission
    }

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone_number: e.target.number.value,
      message: e.target.message.value,
    };

    fetch("https://www.chemilex.com/contactus.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          toast.success(data.message || "Form submitted successfully!", {
            position: "top-right",
            autoClose: 1000,
          });
        } else {
          toast.error(
            data.message || "Failed to submit the form. Please try again.",
            {
              position: "top-right",
              autoClose: 1000,
            }
          );
        }
      })
      .catch((error) => {
        toast.error("Error: Unable to submit form.", {
          position: "top-right",
          autoClose: 1000,
        });
        console.error("Error:", error);
      });

    setUserInput(""); // Reset CAPTCHA input after form submission
  };

  return (
    <>
      {/* Service Detail */}
      <ToastContainer /> {/* Add this to enable toasts */}
      <div className="section-full  p-t80 p-b50  ">
        <div className="container  bg-white ">
          <div className="section-content ">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="mt-box">
                  <h2 className="m-t0">Hydrated Lime: A Crucial Industrial Material</h2>

                  <p>
                  Hydrated lime, also known as calcium hydroxide (Ca(OH)₂), is produced by adding water to quicklime in a process called slaking. This fine, white powder is highly valued for its versatility and reactivity in various industrial applications. 
                  </p>
                  <p>
                  Hydrated lime is widely used in water treatment to soften water and adjust pH levels, in wastewater treatment for neutralizing acidic wastes, and in construction for improving mortar and plaster strength. It is also essential in environmental management for controlling emissions in industrial processes and in agriculture for balancing soil pH and enhancing soil quality. Hydrated lime's broad utility makes it a vital resource in multiple sectors. 
                  </p>
                  
                </div>
              </div>
              <div className="col-lg-6 col-md-12">

              <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    >

                  <div className="item">
                    <div className="aon-thum-bx">
                      <FastImage
                        src="images/products/HydratedLimePowder(2).jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  
                
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-full  p-t80 p-b50  ">
        <div className="container  bg-white ">
          <div className="section-content ">
            <div className="row">
              <div className="col-12">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <h3
    style={{
      marginLeft: "0px",
      width: "100%",
      textAlign:"center"
    }}
  >
    
    Hydrated Lime Powder Specification
  </h3>
  <TableContainer sx={{ maxHeight: 440 }}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align="left"
              style={{ minWidth: column.minWidth }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.SPECIFICATION}>
                <TableCell>{row.SPECIFICATION}</TableCell>
                <TableCell>{row.result1}</TableCell>
                <TableCell>{row.result2}</TableCell>
                <TableCell>{row.result3}</TableCell>
                <TableCell>{row.result4}</TableCell>
                <TableCell>{row.result5}</TableCell>
                <TableCell>{row.result6}</TableCell>
                <TableCell>{row.result7}</TableCell>
                <TableCell>{row.result8}</TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  </TableContainer>
</Paper>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-full p-t80 p-b50 bg-gray">
        <div className="container">
          {/* TITLE START*/}
          <div className="section-head text-center">
            <h2 data-title="Supplies">  Services</h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END*/}
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.QuickLimePowder}>

                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    >
                  
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage
                                src="images/products/QuickLimeLumps.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      
              
                    </OwlCarousel>
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">Calcined/Quick Lime</h4>

                    <NavLink
                      to={route.Products.QuickLimePowder}
                     className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.QuickLimeStone}>
                
                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    >
                   
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage
                            src="images/products/QuickLimeStone.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                       
                
                    </OwlCarousel>
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">  Lime Stone</h4>

                    <NavLink
                      to={route.Products.QuickLimeStone}
                     className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.QuickLimeLumps}>
                    
                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    >
                  
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage
                       
                             src="images/products/QuickLimePowder.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                     
                    
                    </OwlCarousel>
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">Calcined/Quick Lime Powder</h4>

                    <NavLink
                      to={route.Products.QuickLimeLumps}
                     className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full p-t80 p-b50 bg-white ">
        <div className="container  bg-white ">
          <div className="section-content ">
            <div className="row text-center">
              {/* <div className="col-lg-6 col-md-12">
              
                <div className="mt-box  need-help m-b40">
                  <h2>Need Assistance?</h2>
                  <p className="m-b15">
                    <b>
                      {" "}
                      Find answers to common questions about Chemilex, our products, and services. If you need further help, our team is always ready to assist.
                    </b>
                  </p>
                  <p>
                    {" "}
                    For decades, Chemilex has set the standard in quality and reliability. Our commitment to excellence ensures that every interaction you have with us meets your highest expectations. Whether you’re looking for detailed product information or have specific inquiries, we’re here to provide the support you need.
                  </p>
                  <ul className="list-check-circle primary m-b15">
                    <li>
                    Reach out to us for any questions or assistance related to our offerings. Your satisfaction is our top priority, and we’re dedicated to providing prompt and helpful responses to all your inquiries.
                    </li>
                    <li>
                    Simply contact us, and experience the Chemilex difference.
                    </li>
                  </ul>
                  <NavLink
                    to={route.pages.contact.CONTACT1}
                    className="site-button-link"
                  >
                    Contact Us
                  </NavLink>
                </div>
              </div> */}
               <div className="col-lg-12 col-md-12">
                {/* Quick Contact */}
                <div className="widget">
                  <h2> Contact Us</h2>
                  <div className="widget-quick-form">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          placeholder="Name"
                          className="form-control"
                          type="text"
                          name="name"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          placeholder="Email"
                          className="form-control"
                          type="text"
                          name="email"
                        />
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            name="number"
                            type="text"
                            className="form-control"
                            required
                            placeholder="Phone Number"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            rows={5}
                            className="form-control"
                            required
                            placeholder="Message"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>CAPTCHA: {captchaText}</label>
                          <input
                            type="text"
                            placeholder="Enter the CAPTCHA"
                            value={userInput}
                            onChange={(e) => setUserInput(e.target.value)}
                            className="form-control"
                          />
                          <button
                            type="button"
                            onClick={handleGenerateCaptcha}
                            className="site-button m-r10"
                          >
                            Generate Captcha
                          </button>
                          <button
                            type="button"
                            onClick={handleVerifyCaptcha}
                            className="site-button-secondry"
                          >
                            Verify Captcha
                          </button>
                        </div>
                      </div>
                      <div className="form-group">
                        <button type="submit" className="site-button">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HydratedLimePowder;
