// import React, { useState } from "react";

// const CareerForm = () => {
//   const [formData, setFormData] = useState({
//     fullName: "",
//     email: "",
//     phone: "",
//     address: "",

//     jobTitle: "",
//     department: "",

//     coverLetter: "",
//     resume: null,
//   });

//   const handleChange = (e) => {
//     const { name, value, type, checked, files } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]:
//         type === "checkbox" ? checked : type === "file" ? files[0] : value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission logic here
//     console.log(formData);
//   };

//   return (
//     <div className="section-full">
//       <div className="container">
//         <div className="section-head">
//           <h2>Career Application Form</h2>
//           <div className="mt-separator-outer m-b30">
//             <div className="mt-separator site-bg-primary" />
//           </div>
//         </div>
//         <div className="section-content m-b50">
//           <div className="mt-box">
//             <div className="p-a30 bg-gray radius-10 cons-contact-form-wrap">
//               <form
//                 className="cons-contact-form contact-style-1"
//                 onSubmit={handleSubmit}
//                 encType="multipart/form-data"
//               >
//                 <div className="row">
//                   <div className="col-md-6">
//                     <div className="form-group">
//                       <input
//                         name="fullName"
//                         type="text"
//                         required
//                         className="form-control"
//                         placeholder="Full Name"
//                         value={formData.fullName}
//                         onChange={handleChange}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-md-6">
//                     <div className="form-group">
//                       <input
//                         name="email"
//                         type="email"
//                         required
//                         className="form-control"
//                         placeholder="Email Address"
//                         value={formData.email}
//                         onChange={handleChange}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-md-6">
//                     <div className="form-group">
//                       <input
//                         name="phone"
//                         type="tel"
//                         required
//                         className="form-control"
//                         placeholder="Phone Number"
//                         value={formData.phone}
//                         onChange={handleChange}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-md-6">
//                     <div className="form-group">
//                       <input
//                         name="address"
//                         type="text"
//                         required
//                         className="form-control"
//                         placeholder="Address"
//                         value={formData.address}
//                         onChange={handleChange}
//                       />
//                     </div>
//                   </div>

//                   <div className="col-md-6">
//                     <div className="form-group">
//                       <input
//                         name="jobTitle"
//                         type="text"
//                         required
//                         className="form-control"
//                         placeholder="Position Applied For"
//                         value={formData.jobTitle}
//                         onChange={handleChange}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-md-6">
//                     <div className="form-group">
//                       <select
//                         name="department"
//                         className="form-control"
//                         value={formData.department}
//                         onChange={handleChange}
//                       >
//                         <option value="" selected disabled>
//                           Desiered Role
//                         </option>
//                         <option value="">Entry Level</option>
//                         <option value="">Intermidate Level</option>
//                         <option value="">Advance Level</option>
//                       </select>
//                     </div>
//                   </div>
//                   <div className="col-md-12">
//                     <div className="form-group">
//                       <textarea
//                         name="coverLetter"
//                         rows={5}
//                         className="form-control"
//                         required
//                         placeholder="Cover Letter"
//                         value={formData.coverLetter}
//                         onChange={handleChange}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-md-12 p-b30">
//                     <label>Resume/CV:</label>
//                     <input
//                       type="file"
//                       name="resume"
//                       className="form-control"
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>

//                   <div className="col-md-12">
//                     <button type="reset" className="site-button m-r10">
//                       Reset
//                     </button>
//                     <button type="submit" className="site-button-secondry">
//                       Submit
//                     </button>
//                   </div>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CareerForm;


import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CareerForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    address: "",
    jobTitle: "",
    department: "",
    message: "",
    resume: null,
  });
  const [captchaText, setCaptchaText] = useState('');
  const [userInput, setUserInput] = useState('');
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  // CAPTCHA generation
  const generateCaptchaText = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleGenerateCaptcha = () => {
    setCaptchaText(generateCaptchaText());
    setIsCaptchaVerified(false);
    setUserInput('');
  };

  const handleVerifyCaptcha = () => {
    if (userInput === captchaText) {
      setIsCaptchaVerified(true);
      toast.success("CAPTCHA verified successfully!", {
        position: "top-right",
        autoClose: 1000,
      });
    } else {
      setIsCaptchaVerified(false);
      toast.error("CAPTCHA verification failed. Please try again.", {
        position: "top-right",
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    handleGenerateCaptcha();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!isCaptchaVerified) {
      toast.error("Please verify the CAPTCHA before submitting.", { autoClose: 5000 });
      return;
    }
  
    // Function to convert file to base64
    const convertFileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };
  
    // If there is a resume file, convert it to Base64
    if (formData.resume) {
      convertFileToBase64(formData.resume)
        .then((base64Resume) => {
          // Append other form data
          const formDataToSubmit = {
            ...formData,
            resume: base64Resume, // Assign base64-encoded resume string
          };
  
          // Send form data to the server
          fetch("https://www.chemilex.com/career.php", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formDataToSubmit),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.status === "success") {
                toast.success("Form submitted successfully!", { autoClose: 5000 });
              } else {
                toast.error("Form submission failed. Please try again.", { autoClose: 5000 });
              }
            })
            .catch((error) => {
              toast.error("Error: Unable to submit form.", { autoClose: 5000 });
              console.error("Error:", error);
            });
        })
        .catch((error) => {
          toast.error("Error converting resume to Base64.", { autoClose: 5000 });
          console.error("Error:", error);
        });
    }
  };
  
  
 

  return (
    <div className="section-full">
      <div className="container">
        <div className="section-head">
          <h2>Career Application Form</h2>
          <div className="mt-separator-outer m-b30">
            <div className="mt-separator site-bg-primary" />
          </div>
        </div>
        <div className="section-content m-b50">
          <div className="mt-box">
            <div className="p-a30 bg-gray radius-10 cons-contact-form-wrap">
              <form
                className="cons-contact-form contact-style-1"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <div className="row">
                  {/* Full Name */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="name"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Full Name"
                       
                        value={formData.fullName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Email */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="email"
                        type="email"
                        required
                        className="form-control"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Phone */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="phone_number"
                        type="tel"
                        required
                        className="form-control"
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Address */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="address"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Job Title */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="jobTitle"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Position Applied For"
                        value={formData.jobTitle}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Department */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        name="department"
                        className="form-control"
                        value={formData.department}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Desired Role
                        </option>
                        <option value="entry">Entry Level</option>
                        <option value="intermediate">Intermediate Level</option>
                        <option value="advanced">Advanced Level</option>
                      </select>
                    </div>
                  </div>

                  {/* Cover Letter */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        rows={5}
                        className="form-control"
                        required
                        placeholder="Cover Letter"
                        value={formData.coverLetter}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Resume */}
                  <div className="col-md-12 p-b30">
                    <label>Resume/CV:</label>
                    <input
                      type="file"
                      name="resume"
                      className="form-control"
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {/* CAPTCHA */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>CAPTCHA: {captchaText}</label>
                      <input
                        type="text"
                        placeholder="Enter the CAPTCHA"
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        className="form-control"
                      />
                      <button type="button" onClick={handleGenerateCaptcha} className="site-button m-r10">
                      Reset Captcha
                      </button>
                      <button type="button" onClick={handleVerifyCaptcha} className="site-button-secondry">
                        Verify Captcha
                      </button>
                    </div>
                  </div>

                  {/* Submit */}
                  <div className="col-md-12">
                    <button type="reset" className="site-button m-r10">
                      Reset
                    </button>
                    <button type="submit" className="site-button-secondry">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerForm;

