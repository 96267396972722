/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
// import { useEffect, useState } from "react";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// function ContactUs1Page() {
//   const [captchaText, setCaptchaText] = useState('');
//   const [userInput, setUserInput] = useState('');
//   const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

//   const generateCaptchaText = () => {
//     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     let result = '';
//     for (let i = 0; i < 6; i++) {
//       result += characters.charAt(Math.floor(Math.random() * characters.length));
//     }
//     return result;
//   };

//   const handleGenerateCaptcha = () => {
//     setCaptchaText(generateCaptchaText());
//     setIsCaptchaVerified(false);
//     setUserInput('');
//   };

//   const handleVerifyCaptcha = () => {
    
//     if (userInput === captchaText) {
//       setIsCaptchaVerified(true);
//       toast.success("CAPTCHA verified successfully!", {
//         position: "top-right",
//         autoClose: 5000,
//       });
//     } else {
//       setIsCaptchaVerified(false);
//       toast.error("CAPTCHA verification failed. Please try again.", {
//         position: "top-right",
//         autoClose: 5000,
//       });
//     }
//   };

//   useEffect(() => {
//     handleGenerateCaptcha();
//   }, []);

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Check if CAPTCHA is verified
//     if (!isCaptchaVerified) {
//       toast.error("Please verify the CAPTCHA before submitting.", {
//         position: "top-right",
//         autoClose: 5000,
//       });
//       return; // Prevent submission
//     }

//     const formData = {
//       name: e.target.name.value,
//       email: e.target.email.value,
//       phone_number: e.target.phone_number.value,
//       address: e.target.address.value,
//       subject: e.target.subject.value,
//       select_product: e.target.select_product.value,
//       message: e.target.message.value,
//     };

//     fetch("https://chemilex.com/api.php", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(formData),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status === "success") {
//           toast.success(data.message || "Form submitted successfully!", {
//             position: "top-right",
//             autoClose: 5000,
//           });
//         } else {
//           toast.error(data.message || "Failed to submit the form. Please try again.", {
//             position: "top-right",
//             autoClose: 5000,
//           });
//         }
//       })
//       .catch((error) => {
//         toast.error("Error: Unable to submit form.", {
//           position: "top-right",
//           autoClose: 5000,
//         });
//         console.error("Error:", error);
//       });
//   };


//   return (
//     <>
//       {/* CONTACT DETAIL BLOCK */}
//       <div className="section-full p-t80">
//         <div className="container">
//           {/* Google Map BLock */}
//           <div className="section-content">
//             {/* Location BLock */}
//             <div className="mt-box">
//               <div className="gmap-outline">
//                 <div className="google-map" style={{ width: "100%" }}>
//                   <iframe
//                     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d611.2680239810283!2d73.51806766802699!3d26.866118466351324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396a7fc209085ec5%3A0xde72d3e585856fd8!2sChemilex%20industries!5e0!3m2!1sen!2sin!4v1725429882551!5m2!1sen!2sin"
//                     width="600"
//                     height="450"
//                     allowfullscreen=""
//                     loading="lazy"
//                     referrerpolicy="no-referrer-when-downgrade"
//                   ></iframe>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="section-full p-t80">
//         <div className="container">
//           <div className="section-head">
//             <h2>Contact Us </h2>
//             <div className="mt-separator-outer m-b30">
//               <div className="mt-separator site-bg-primary" />
//             </div>
//           </div>
//           <div className="section-content m-b30">
//             <div className="row">
//               <div className="col-md-6 col-sm-12 m-b30">
//                 <div className="mt-icon-box-wraper center p-a30 bg-gray">
//                   <div className="icon-sm m-b10">
//                     <i className="iconmoon-smartphone-1" />
//                   </div>
//                   <div className="icon-content">
//                     <h4 className="site-text-primary">Phone number</h4>
//                     <a href="tel:+912617961531">
//                       <p>
//                         {" "}
//                         <span>Office No:</span>+91-261-7961531
//                       </p>
//                     </a>
//                     <a href="tel:+919909107770">
//                       <p>
//                         {" "}
//                         <span>Mobile No:</span>+91 99091 07770
//                       </p>
//                     </a>
//                     <a href="tel:+919879879925">
//                       <p>
//                         <span>Mobile No:</span>+91 98798 79925
//                       </p>
//                     </a>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-md-6 col-sm-12 m-b30">
//                 <div className="mt-icon-box-wraper center p-a30 bg-gray">
//                   <div className="icon-sm m-b10">
//                     <i className="iconmoon-email" />
//                   </div>
//                   <div className="icon-content">
//                     <h4 className="site-text-primary">Email address</h4>
//                     <a href="mailto:tejas.afrikawala@chemilex.com">
//                       <p>tejas.afrikawala@chemilex.com</p>
//                     </a>
//                     <a href="mailto:info@chemilex.com">
//                       <p>info@chemilex.com</p>
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="section-content m-b30">
//             <div className="section-head">
//               <h2>Our Locations </h2>
//               <div className="mt-separator-outer m-b30">
//                 <div className="mt-separator site-bg-primary" />
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-md-6 col-sm-12 m-b30">
//                 <div className="mt-icon-box-wraper center p-a30 bg-gray">
//                   <div className="icon-sm m-b10">
//                     <i className="iconmoon-travel" />
//                   </div>
//                   <div className="icon-content">
//                     <h4 className="site-text-primary">Factory Address</h4>
//                     <a href>
//                       <p>
//                         {" "}
//                         Khasra No-172/3, Manakpur Industrial Area,
//                         <br />
//                         Post Basni Hari Singh, Tehsil Bhopalgarh,
//                         <br />
//                         Jodhpur, Rajasthan - 342901, India
//                       </p>
//                     </a>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-md-6 col-sm-12 m-b30">
//                 <div className="mt-icon-box-wraper center p-a30 bg-gray">
//                   <div className="icon-sm m-b10">
//                     <i className="iconmoon-travel" />
//                   </div>
//                   <div className="icon-content">
//                     <h4 className="site-text-primary">
//                       Corporate Office Address
//                     </h4>
//                     <a href>
//                       <p>
//                         {" "}
//                         No-12, Ashwamegh Palace,
//                         <br />
//                         Opp.Shreenathji Gardenia, Kosad,
//                         <br />
//                         Surat, Gujarat - 394107, India
//                       </p>
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="section-full">
//         <div className="container">
//           <div className="section-head">
//             <h2>Contact Form </h2>
//             <div className="mt-separator-outer m-b30">
//               <div className="mt-separator site-bg-primary" />
//             </div>
//           </div>
//           {/* GOOGLE MAP & CONTACT FORM */}
//           <div className="section-content m-b50">
//             {/* CONTACT FORM*/}
//             <div className="mt-box">
//               <div className="p-a30 bg-gray radius-10 cons-contact-form-wrap">
//                 <form
//                   className="cons-contact-form contact-style-1"
//                   onSubmit={(e)=>{
//                     handleSubmit(e)
//                     setUserInput('')
//                   }}
//                 >
//                   <div className="row">
//                     <div className="col-md-12">
//                       <div className="form-group">
//                         <input
//                           name="username"
//                           type="text"
//                           required
//                           className="form-control"
//                           placeholder="Name"
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-12">
//                       <div className="form-group">
//                         <input
//                           name="email"
//                           type="text"
//                           className="form-control"
//                           required
//                           placeholder="Email"
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-12">
//                       <div className="form-group">
//                         <input
//                           name="number"
//                           type="number"
//                           className="form-control"
//                           required
//                           placeholder="Number"
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-12">
//                       <div className="form-group">
//                         <textarea
//                           name="message"
//                           rows={5}
//                           className="form-control "
//                           required
//                           placeholder="Message"
//                           defaultValue={""}
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-12">
//                       <div className="form-group">
//                         <label>CAPTCHA: {captchaText}</label>
//                         <input
//                           type="text"
//                           placeholder="Enter the CAPTCHA"
//                           value={userInput}
//                           onChange={(e) => setUserInput(e.target.value)}
//                           className="form-control"
//                         />
//                         <button type="button" onClick={handleGenerateCaptcha} className="site-button m-r10">
//                           Generate New CAPTCHA
//                         </button>
//                         <button type="button" onClick={handleVerifyCaptcha} className="site-button-secondry">
//                           Verify
//                         </button>
//                       </div>
//                     </div>
//                     <div className="col-md-12">
//                       <button
//                         name="Resat"
//                         type="reset"
//                         value="Reset"
//                         className="site-button m-r10"
//                       >
//                         Reset
//                       </button>
//                       <button
//                         name="submit"
//                         type="submit"
//                         value="Submit"
//                         className="site-button-secondry"
//                       >
//                         Submit{" "}
//                       </button>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
// export default ContactUs1Page;

import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactUs1Page() {
  const [captchaText, setCaptchaText] = useState('');
  const [userInput, setUserInput] = useState('');
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const generateCaptchaText = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleGenerateCaptcha = () => {
    setCaptchaText(generateCaptchaText());
    setIsCaptchaVerified(false);
    setUserInput('');
  };

  const handleVerifyCaptcha = () => {
    if (userInput === captchaText) {
      setIsCaptchaVerified(true);
      toast.success("CAPTCHA verified successfully!", {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      setIsCaptchaVerified(false);
      toast.error("CAPTCHA verification failed. Please try again.", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    handleGenerateCaptcha();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if CAPTCHA is verified
    if (!isCaptchaVerified) {
      toast.error("Please verify the CAPTCHA before submitting.", {
        position: "top-right",
        autoClose: 5000,
      });
      return; // Prevent submission
    }

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone_number: e.target.number.value,
      message: e.target.message.value,
    };

    fetch("https://www.chemilex.com/contactus.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          toast.success(data.message || "Form submitted successfully!", {
            position: "top-right",
            autoClose: 1000,
          });
        } else {
          toast.error(data.message || "Failed to submit the form. Please try again.", {
            position: "top-right",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {
        toast.error("Error: Unable to submit form.", {
          position: "top-right",
          autoClose: 1000,
        });
        console.error("Error:", error);
      });

    setUserInput(''); // Reset CAPTCHA input after form submission
  };

  return (
    <>
        <ToastContainer /> {/* Add this to enable toasts */}
      {/* CONTACT DETAIL BLOCK */}
      <div className="section-full p-t80">
        <div className="container">
          {/* Google Map Block */}
          <div className="section-content">
            <div className="mt-box">
              <div className="gmap-outline">
                <div className="google-map" style={{ width: "100%" }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d611.2680239810283!2d73.51806766802699!3d26.866118466351324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396a7fc209085ec5%3A0xde72d3e585856fd8!2sChemilex%20industries!5e0!3m2!1sen!2sin!4v1725429882551!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Us Section */}
      <div className="section-full p-t80">
        <div className="container">
          <div className="section-head">
            <h2>Contact Us</h2>
            <div className="mt-separator-outer m-b30">
              <div className="mt-separator site-bg-primary"></div>
            </div>
          </div>

          {/* Contact Form */}
          <div className="section-content m-b50">
            <div className="mt-box">
              <div className="p-a30 bg-gray radius-10 cons-contact-form-wrap">
                <form className="cons-contact-form contact-style-1" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input name="name" type="text" required className="form-control" placeholder="Name" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input name="email" type="email" className="form-control" required placeholder="Email" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input name="number" type="text" className="form-control" required placeholder="Phone Number" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          rows={5}
                          className="form-control"
                          required
                          placeholder="Message"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>CAPTCHA: {captchaText}</label>
                        <input
                          type="text"
                          placeholder="Enter the CAPTCHA"
                          value={userInput}
                          onChange={(e) => setUserInput(e.target.value)}
                          className="form-control"
                        />
                        <button type="button" onClick={handleGenerateCaptcha} className="site-button m-r10">
                          Generate Captcha
                        </button>
                        <button type="button" onClick={handleVerifyCaptcha} className="site-button-secondry">
                          Verify Captcha
                        </button>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button type="reset" className="site-button m-r10">
                        Reset
                      </button>
                      <button type="submit" className="site-button-secondry">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs1Page;

