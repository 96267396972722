import { useEffect, useState } from "react";
// import FastImage from "../../../globals/elements/fastimg";
// import { loadScript, route } from "../../../globals/constants";
import { NavLink } from "react-router-dom";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import OwlCarousel from 'react-owl-carousel';
import { toast, ToastContainer } from "react-toastify";
function ChromeConcentrate() {
  useEffect(() => {
    loadScript("js/custom.js");
  });
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const generateCaptchaText = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleGenerateCaptcha = () => {
    setCaptchaText(generateCaptchaText());
    setIsCaptchaVerified(false);
    setUserInput("");
  };

  const handleVerifyCaptcha = () => {
    if (userInput === captchaText) {
      setIsCaptchaVerified(true);
      toast.success("CAPTCHA verified successfully!", {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      setIsCaptchaVerified(false);
      toast.error("CAPTCHA verification failed. Please try again.", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    handleGenerateCaptcha();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if CAPTCHA is verified
    if (!isCaptchaVerified) {
      toast.error("Please verify the CAPTCHA before submitting.", {
        position: "top-right",
        autoClose: 5000,
      });
      return; // Prevent submission
    }

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone_number: e.target.number.value,
      message: e.target.message.value,
    };

    fetch("https://www.chemilex.com/contactus.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          toast.success(data.message || "Form submitted successfully!", {
            position: "top-right",
            autoClose: 1000,
          });
        } else {
          toast.error(
            data.message || "Failed to submit the form. Please try again.",
            {
              position: "top-right",
              autoClose: 1000,
            }
          );
        }
      })
      .catch((error) => {
        toast.error("Error: Unable to submit form.", {
          position: "top-right",
          autoClose: 1000,
        });
        console.error("Error:", error);
      });

    setUserInput(""); // Reset CAPTCHA input after form submission
  };

  return (
    <>
      {/* Service Detail */}
      <ToastContainer /> {/* Add this to enable toasts */}
      <div className="section-full  p-t80 p-b50  ">
        <div className="container  bg-white ">
          <div className="section-content ">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="mt-box">
                  <h2 className="m-t0">Chrome Concentrate</h2>

                  <p>
                    Chrome Concentrate is a high-grade material derived from
                    chromite ore, known for its high chromium content and low
                    impurity levels. It is widely used in the production of
                    ferrochrome, a key component in stainless steel
                    manufacturing. The high-quality concentrate offers excellent
                    metallurgical properties, making it a vital resource for
                    industries that require strong, corrosion-resistant steel.
                  </p>
                  <p>
                  The Chrome Concentrate is sourced
                    from the best mining regions and processed to meet stringent
                    quality standards. Its high chromium-to-iron ratio ensures
                    optimal performance in smelting processes, making it an
                    essential material for stainless steel producers. The
                    reliability and purity of our Chrome Concentrate help
                    manufacturers achieve superior product quality, making it a
                    trusted choice for industrial applications.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
              <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    > 
                  <div className="item">
                    <div className="aon-thum-bx">
                      <FastImage src="images/products/52.jpg" alt="" />
                    </div>
                  </div>
                  
                  </OwlCarousel>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full p-t80 p-b50 bg-gray">
        <div className="container">
          {/* TITLE START*/}
          <div className="section-head text-center">
            <h2 data-title="Supplies">  Services</h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END*/}
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.Gypsum}>
                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    > 
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage src="images/products/51.jpg" alt="" />
                        </div>
                      </div>
                      </OwlCarousel>

                     
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">Gypsum</h4>

                    <NavLink to={route.Products.Gypsum} className="site-button-link site-text-primary font-weight-600">
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
             <div className="mt-box bg-white m-b30 mt-service-2">
                <div className="mt-media">
                  <NavLink to={route.Products.ImportedMagnesesOre}>
                  <OwlCarousel
                      className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20"
                      items={1}
                      loop
                      nav
                      dots
                      mouseDrag={false} // Disable mouse dragging
                      touchDrag={false} // Disable touch dragging
                    > 
                      <div className="item">
                        <div className="aon-thum-bx">
                          <FastImage src="images/products/Our Products .jpg" alt="" />
                        </div>
                      </div>
                      </OwlCarousel>
                     
                  </NavLink>
                </div>
                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                  <div className="icon-content">
                    <h4 className="mt-tilte m-t0">
                      Imported Magneses Ore(East Africa)
                    </h4>

                    <NavLink
                      to={route.Products.ImportedMagnesesOre}
                     className="site-button-link site-text-primary font-weight-600"
                    >
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full p-t80 p-b50 bg-white ">
        <div className="container  bg-white ">
          <div className="section-content ">
            <div className="row text-center">
              {/* <div className="col-lg-6 col-md-12">
              
                <div className="mt-box  need-help m-b40">
                  <h2>Need Assistance?</h2>
                  <p className="m-b15">
                    <b>
                      {" "}
                      Find answers to common questions about Chemilex, our products, and services. If you need further help, our team is always ready to assist.
                    </b>
                  </p>
                  <p>
                    {" "}
                    For decades, Chemilex has set the standard in quality and reliability. Our commitment to excellence ensures that every interaction you have with us meets your highest expectations. Whether you’re looking for detailed product information or have specific inquiries, we’re here to provide the support you need.
                  </p>
                  <ul className="list-check-circle primary m-b15">
                    <li>
                    Reach out to us for any questions or assistance related to our offerings. Your satisfaction is our top priority, and we’re dedicated to providing prompt and helpful responses to all your inquiries.
                    </li>
                    <li>
                    Simply contact us, and experience the Chemilex difference.
                    </li>
                  </ul>
                  <NavLink
                    to={route.pages.contact.CONTACT1}
                    className="site-button-link"
                  >
                    Contact Us
                  </NavLink>
                </div>
              </div> */}
              <div className="col-lg-12 col-md-12">
                {/* Quick Contact */}
                <div className="widget">
                  <h2> Contact Us</h2>
                  <div className="widget-quick-form">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          placeholder="Name"
                          className="form-control"
                          type="text"
                          name="name"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          placeholder="Email"
                          className="form-control"
                          type="text"
                          name="email"
                        />
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            name="number"
                            type="text"
                            className="form-control"
                            required
                            placeholder="Phone Number"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            rows={5}
                            className="form-control"
                            required
                            placeholder="Message"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>CAPTCHA: {captchaText}</label>
                          <input
                            type="text"
                            placeholder="Enter the CAPTCHA"
                            value={userInput}
                            onChange={(e) => setUserInput(e.target.value)}
                            className="form-control"
                          />
                          <button
                            type="button"
                            onClick={handleGenerateCaptcha}
                            className="site-button m-r10"
                          >
                            Generate Captcha
                          </button>
                          <button
                            type="button"
                            onClick={handleVerifyCaptcha}
                            className="site-button-secondry"
                          >
                            Verify Captcha
                          </button>
                        </div>
                      </div>
                      <div className="form-group">
                        <button type="submit" className="site-button">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ChromeConcentrate;
